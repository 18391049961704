var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticStyle:{"margin":"20px"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.printLoading,"disabled":_vm.printLoading},on:{"click":_vm.generatePdf}},[(!_vm.printLoading)?_c('a-icon',{attrs:{"type":"file-pdf"},on:{"click":_vm.generatePdf}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("action.imprimerTab"))+" ")],1),_c('a-range-picker',{staticStyle:{"margin-left":"20px"},attrs:{"placeholder":[_vm.$t('paiement.dateDebut'), _vm.$t('paiement.dateFin')],"format":"DD/MM/YYYY","mode":_vm.mode},on:{"change":function (val) {
            _vm.handleDateRange(val);
          }},model:{value:(_vm.dateRangeData),callback:function ($$v) {_vm.dateRangeData=$$v},expression:"dateRangeData"}})],1),_c('div',[_c('a-table',{attrs:{"row-selection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onChange: _vm.onSelectChange,
          onSelectAll: _vm.onSelectAll,
        },"rowKey":"_id","pagination":true,"data-source":_vm.data,"columns":_vm.columns,"loading":_vm.tableLoading,"scroll":{ x: 'max-content' }},on:{"change":_vm.tableChanged},scopedSlots:_vm._u([{key:"serial",fn:function(text, record){return [(
              record.status == 'cancelled' &&
              !(record.cancellation && record.cancellation.type == 'Avoir')
            )?_c('a-tag',{attrs:{"color":"red"}},[_vm._v(_vm._s(text))]):(
              record.cancellation && record.cancellation.type == 'Avoir'
            )?_c('a-tag',{attrs:{"color":"orange"}},[_vm._v(_vm._s(text))]):_c('p',[_vm._v(_vm._s(text))])]}},{key:"paymentOnWhat",fn:function(text, record){return [_c('div',{staticClass:"d-inline"},[(record.fees)?_c('div',_vm._l((record.fees),function(value,key){return _c('a-tag',{key:key,attrs:{"color":"blue"}},[_c('span',[_vm._v(_vm._s(_vm.formatFeeName(key)))])])}),1):_vm._e()])]}},{key:"receiptType",fn:function(text, record){return [(record.cheques.length > 0)?_c('a-button',{attrs:{"icon":"search","type":"primary","block":""},on:{"click":function($event){return _vm.chequeDetailsModal(record)}}},[_vm._v(" "+_vm._s(text)+" ")]):_c('div',[_vm._v(_vm._s(text))])]}},{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(_vm.moment(text).format("DD/MM/YYYY"))+" ")]}},{key:"filterDropdown",fn:function(ref){
          var setSelectedKeys = ref.setSelectedKeys;
          var selectedKeys = ref.selectedKeys;
          var confirm = ref.confirm;
          var clearFilters = ref.clearFilters;
          var column = ref.column;
return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(function (c) { return (_vm.searchInput = c); }),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":((_vm.$t('paiement.chercher')) + " " + (column.title)),"value":selectedKeys[0]},on:{"change":function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); },"pressEnter":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}},[_vm._v(_vm._s(_vm.$t("scolarite.recherche")))]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":function () { return _vm.handleReset(clearFilters); }}},[_vm._v(_vm._s(_vm.$t("action.reinitialiser")))])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                .toString()
                .split(
                  new RegExp(("(?<=" + _vm.searchText + ")|(?=" + _vm.searchText + ")"), 'i')
                )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(_vm._s(text))]]}},{key:"userName",fn:function(text){return _c('a-tag',{attrs:{"color":"#2db7f5"}},[_vm._v(" "+_vm._s(text ? text : "--")+" ")])}},{key:"print",fn:function(text, record){return _c('a-button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.print(record); }}},[_c('a-icon',{attrs:{"type":"file-pdf"}}),_vm._v(_vm._s(_vm.$t("action.imprimer"))+" ")],1)}},{key:"annulation",fn:function(text, record){return _c('a-button',{attrs:{"type":"danger","loading":_vm.loadingAnnulation,"disabled":_vm.loadingAnnulation || record.status == 'cancelled'},on:{"click":function () {
              _vm.selectedRecord = record;
              _vm.visibleCancelModal = true;
            }}},[_c('a-icon',{attrs:{"type":"delete","href":"javascript:;"}}),_vm._v(_vm._s(_vm.$t("recette.annulation"))+" ")],1)}}])})],1)]),(_vm.chequeVisible)?_c('chequeModal',{attrs:{"chequeVisible":_vm.chequeVisible,"activeCheques":_vm.activeCheques},on:{"close":_vm.handleClose}}):_vm._e(),_c('a-modal',{attrs:{"title":_vm.$t('paiement.annulation')},on:{"ok":function($event){return _vm.annulation(_vm.selectedRecord)}},model:{value:(_vm.visibleCancelModal),callback:function ($$v) {_vm.visibleCancelModal=$$v},expression:"visibleCancelModal"}},[_c('a-label',[_vm._v(_vm._s(_vm.$t("recette.type"))+" : ")]),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"ml-2 mr-2"},[_vm._v(_vm._s(_vm.$t("paiement.avoir")))]),_c('a-switch',{class:_vm.cancelType == 'Avoir' ? 'yellow-switch' : 'red-switch',attrs:{"default-checked":""},on:{"change":function (val) {
            _vm.cancelType = val ? 'Annulation' : 'Avoir';
            _vm.visibleCancelModal = true;
          }}}),_c('span',{staticClass:"ml-2 mr-2"},[_vm._v(_vm._s(_vm.$t("paiement.annulation")))])],1),_c('a-label',[_vm._v(_vm._s(_vm.$t("paiement.note"))+" : ")]),_c('a-textarea',{attrs:{"placeholder":_vm.$t('paiement.note'),"auto-size":{ minRows: 3, maxRows: 5 }},model:{value:(_vm.cancelNote),callback:function ($$v) {_vm.cancelNote=$$v},expression:"cancelNote"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }